import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TweenMax from "gsap/TweenMax";
import './404.scss'
import BatteryIcon from '../../content/assets/battery_icon.png'
import four_o_four_page from '../../content/assets/page_images/four_o_four_page.webp'

class NotFoundPage extends React.Component {

  intervalID = 0;

  componentDidMount(){
    // -------COUNTER------- //

    var countdown = document.getElementById("countdown");
    // function
    var countItDown = function() {
      var currentTime = parseFloat(countdown.textContent);
      if (currentTime < 97) {
        countdown.textContent = currentTime + 1;
      } else {
        countdown.textContent = 56;
      }

    };
    // set interval 
    this.intervalID = setInterval(countItDown, 100);


    //make radial lines
    const radial = document.querySelector('.radial');
    makeRadial({
      el: radial,
      radials: 100
    })

    function makeRadial(options) {
      if (options && options.el) {
        var el = options.el;
        var radials = 60;
        if (options.radials) {
          radials = options.radials;
        }
        var degrees = 360 / radials;
        var i = 0;
        for (i = 0; i < (radials / 2); i++) {
          var newTick = document.createElement('div');
          newTick.classList.add('tick');
          var c = newTick.style;
          c.transform = 'rotate(' + (i * degrees) + 'deg)';
          el.prepend(newTick);
        }
      }
    }

    // -------ANIMATIONS------- //

    var main = document.querySelector(".main");
    var secondSlide = document.querySelector(".second-slide");
    var home = document.querySelector(".home");
    var thirdSlide = document.querySelector(".third-slide");
    var mode = "home";

    main.addEventListener("click", function(){
      if (mode === "home") {
        TweenMax.to(home, 0.4, {
          y: -360
        })
        TweenMax.fromTo(secondSlide, 0.4, {
          y: 360,
          autoAlpha: 1
        }, {
          y: 0
  
        })
        mode = "second-slide";
      } else if (mode === "second-slide") {
          TweenMax.to(secondSlide, 0.4, {
            y: -360
          })
          TweenMax.fromTo(thirdSlide, 0.4, {
            y: 360,
            autoAlpha: 1
          }, {
            y: 0
          })
          mode = "thirdSlide";
      } else if (mode === "thirdSlide") {
          TweenMax.to(home, 0.2, {
            y: 0
          })
          TweenMax.fromTo(thirdSlide, 0.2, {
            y: 0,
            autoAlpha: 1
          }, {
            y: 360
          })
          mode = "home";
      }
    })

  }

  componentWillUnmount() {
    clearInterval(this.intervalID)
  }

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const frontmatter = {
      slug: '404-not-found'
    };

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="404: Not Found"
          description="You hit the page that doesn't exist, what a bummer..."
          image={four_o_four_page}
          frontmatter={frontmatter}
        />
        <main>
          <h1 className="blog-heading">Not Found</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
          <div className="four-o-four">
            <div className="main">
              <div className="screen">
                <div className="home">
                  <div className="radial">
                    <div className="empty"></div>
                  </div>
                  <div className="icon-404">
                    <img src={BatteryIcon} alt=""/>
                  </div>
                  <div className="status">15%</div>
                  <h1 id="countdown">0</h1>
                  <div className="mph">mph</div>
                  <a href="https://shop.emkit.net/shop/" className="screen-404-cta">Visit Shop</a>
                </div>
                <div className="second-slide">
                  <h2>READ<br/>ARTICLES</h2>
                  <Link className="screen-404-cta" to="/blog">Visit Blog</Link>
                </div>
                <div className="third-slide">
                  <h2>LEARN HOW<br/>PW WORKS</h2>
                  <Link className="screen-404-cta" to="/documentation">Visit Docs</Link>
                </div>
              </div>
            </div>
          </div>
        </main>
      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
